/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'gatsby';
import { ArrowDownIcon } from '@assets/icons/styled/StyledIcons';
import removeHtmlTags from '../helpers/removeHtmlTags';
import { IEpisodeGalleryProps } from '../interface';
import {
  AllEpisodesWrapper,
  DateLabel,
  EpisodeArtwork,
  EpisodeDescription,
  EpisodeDetails,
  EpisodeTitle,
  MoreEpisodesButton,
  MoreEpisodesLabel,
  SmallEpisodeWrapper,
} from './EpisodeGalleryStyled';
import { episodesPerPage } from './constants';

const EpisodeGallery: React.FC<IEpisodeGalleryProps> = ({
  combinedEpisodes,
  podcastSlug,
}) => {
  const [numberOfEpisodes, setNumberOfEpisodes] = useState(episodesPerPage);

  return (
    <AllEpisodesWrapper>
      {combinedEpisodes.slice(0, numberOfEpisodes).map((episode) => (
        <Link
          to={`/${podcastSlug}/${episode?.slug?.localized}`}
          key={episode.buzzsproutId}
        >
          <SmallEpisodeWrapper>
            <EpisodeArtwork src={episode.artwork_url} />
            <EpisodeDetails>
              <DateLabel>
                {format(new Date(episode.published_at), 'LLL d, yyyy')}
              </DateLabel>
              <EpisodeTitle>{episode.episodeTitle}</EpisodeTitle>
              <EpisodeDescription>
                {removeHtmlTags(episode.episodeDescription)}
              </EpisodeDescription>
            </EpisodeDetails>
          </SmallEpisodeWrapper>
        </Link>
      ))}
      <MoreEpisodesButton
        disabled={numberOfEpisodes >= combinedEpisodes.length}
        onClick={() => {
          setNumberOfEpisodes(numberOfEpisodes + episodesPerPage);
        }}
      >
        <MoreEpisodesLabel>MORE EPISODES</MoreEpisodesLabel>
        <ArrowDownIcon width={15} height={15} />
      </MoreEpisodesButton>
    </AllEpisodesWrapper>
  );
};
export default EpisodeGallery;
