/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { PodcastWrapper, Subtitle, Title } from './PodcastStyled';
import { IAllEpisodesProps } from './interface';
import combineEpisodes from './helpers/combineEpisodes';
import EpisodeGallery from './EpisodeGallery/EpisodeGallery';

const AllEpisodes: React.FC<IAllEpisodesProps> = ({
  podcast,
  buzzsproutEpisodes,
}) => {
  const { allEpisodesTitle, allEpisodesSubtitle, slug } = podcast;

  const combinedEpisodes = combineEpisodes({
    podcast,
    buzzsproutEpisodes,
  });

  return (
    <PodcastWrapper>
      {allEpisodesTitle && <Title>{allEpisodesTitle?.localized}</Title>}
      {allEpisodesSubtitle && (
        <Subtitle>{allEpisodesSubtitle?.localized}</Subtitle>
      )}
      <EpisodeGallery
        combinedEpisodes={combinedEpisodes}
        podcastSlug={slug.localized}
      />
    </PodcastWrapper>
  );
};

export default AllEpisodes;
