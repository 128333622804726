import React, { useEffect } from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import {
  AllPodcastEpisodesQuery,
  AllPodcastEpisodesQueryVariables,
} from 'graphql-types';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { HeaderFragmentWithVariant } from '@components/Layout/NavigationBar/interfaces';
import AllEpisodes from '@components/Podcast/AllEpisodes';
import PageWrapper from './shared/PageWrapper';
import { analyticsSnippet } from './analytics/analytics';
import trackPage from './analytics/trackPage';

const AllPodcastEpisodes = ({
  data,
  pageContext,
}: PageProps<AllPodcastEpisodesQuery, AllPodcastEpisodesQueryVariables>) => {
  useSaveUtmParamsToLocalStorage();

  useEffect(() => {
    trackPage({
      pageSlug: data?.sanityPodcast?.slug?.localized,
    });
  }, [data?.sanityPodcast?.slug?.localized]);

  return (
    <PageWrapper
      locale={pageContext.locale}
      navigationBar={
        data?.sanityPodcast?.navigationBar as HeaderFragmentWithVariant
      }
      transparentNavigationBar={data?.sanityPodcast?.transparentNavigationBar}
      metadata={data?.sanityPodcast?.metadata}
      announcementId={data?.sanityPodcast?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      <AllEpisodes
        buzzsproutEpisodes={data.buzzsproutEpisodes.edges}
        podcast={data.sanityPodcast}
      />
    </PageWrapper>
  );
};

export const Head = () => (
  <>
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: analyticsSnippet,
      }}
    />
    <Script
      async
      src="https://tag.clearbitscripts.com/v1/pk_54aa5d556dad4b9548cc7054eba8b2ca/tags.js"
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </>
);

export const query = graphql`
  query AllPodcastEpisodes($locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    sanityPodcast {
      ...PodcastData
    }
    buzzsproutEpisodes: allBuzzsproutPodcastEpisode(
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          ...BuzzsproutEpisodes
        }
      }
    }
  }
`;

// ts-prune-ignore-next
export default AllPodcastEpisodes;
