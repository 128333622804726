import styled from 'styled-components';

export const AllEpisodesWrapper = styled.div`
  display: flex;
  padding: 0 100px;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ theme }) => theme.BreakpointLargeDesktop`
    padding: unset;
  `};
`;

export const SmallEpisodeWrapper = styled.div`
  box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12),
    0 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 25px 50px;
  width: 369px;
  height: 520px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1456px) {
    width: 330px;
    height: 475px;
  }
`;

export const EpisodeArtwork = styled.img`
  border-radius: 15px;
  height: 300px;
  width: 300px;
  margin: 30px 40px 15px 40px;
  @media (max-width: 1456px) {
    height: 250px;
    width: 250px;
  }
`;

export const EpisodeDetails = styled.div`
  margin: 0 44px;
`;

export const DateLabel = styled.div`
  color: ${({ theme }) => theme.colors.gray.santasGray};
  font-family: ${({ theme }) => theme.fonts.Walsheim.regular};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
`;

export const EpisodeTitle = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.Walsheim.bold};
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  margin-top: 5px;
  display: -webkit-box;
  max-width: inherit;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const EpisodeDescription = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.black};
  display: -webkit-box;
  max-width: inherit;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 15px;
  line-height: 17px;
`;

export const MoreEpisodesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  width: 100%;
  margin-top: 30px;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.blue.plumCake};
    }
  }
  :disabled {
    display: none;
  }
`;

export const MoreEpisodesLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.Walsheim.bold};
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.blue.plumCake};
  margin-right: 8px;
`;
